import { ListSubheader } from '@mui/material';

import { ICustomOptionsMapper } from './types';
import { ItemText } from './styles';

export const getArrayMenuItemsToRenderSelect = (
  options: string[],
  customOptionsMappper?: ICustomOptionsMapper
): JSX.Element[] =>
  options.map(
    customOptionsMappper ||
      ((opt: string, idx: number) => (
        <ItemText key={idx} value={opt}>
          {opt}
        </ItemText>
      ))
  );

export const getMapMenuItemsToRenderSelect = (
  options: Map<string, string[]>,
  customOptionsMapper?: ICustomOptionsMapper
): JSX.Element[] => {
  const optionEntries: [string, string[]][] = Array.from(options.entries());
  const itemsToRender: JSX.Element[] = [];

  optionEntries.forEach(([subheaderName, optionsList]) => {
    itemsToRender.push(<ListSubheader key={subheaderName}>{subheaderName}</ListSubheader>);

    for (let i = 0, len = optionsList.length; i < len; i++) {
      const currentOption: string = optionsList[i];

      itemsToRender.push(
        customOptionsMapper ? (
          customOptionsMapper(currentOption, i)
        ) : (
          <ItemText key={`${subheaderName}-${i}`} value={currentOption}>
            {currentOption}
          </ItemText>
        )
      );
    }
  });

  return itemsToRender;
};
