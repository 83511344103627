import { FC, ReactNode } from 'react';

import { FilePreview } from '/shared/FilePreview';

import { ISFCOption, SelectFormControlDataProps } from './types';
import { SelectFormControlObject } from './SelectFormControlObject';
import { ItemIcon } from './styles';

export function SelectFormControlData<T>(props: SelectFormControlDataProps<T>) {
  const getOptionValue = (opt: ISFCOption<T>): string => opt.label;
  const getOptionLabel = (opt: ISFCOption<T>): ReactNode => (
    <>
      {opt.iconId && (
        <ItemIcon>
          <FilePreview source={opt.iconId} />
        </ItemIcon>
      )}
      {opt.label}
    </>
  );
  return (
    <SelectFormControlObject<ISFCOption<T>>
      {...props}
      getOptionValue={getOptionValue}
      getOptionLabel={getOptionLabel}
    />
  );
}

function ofType<T = string>() {
  return SelectFormControlData as FC<SelectFormControlDataProps<T>>;
}

SelectFormControlData.ofType = ofType;
