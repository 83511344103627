import { styled } from '@mui/material';

import { CircularProgressStyled } from './Spinner';

export const Backdrop = styled('div')`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.palette.background.paper};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PageLoaderSpinner = () => {
  return (
    <Backdrop>
      <CircularProgressStyled role='progressbar' />
    </Backdrop>
  );
};
