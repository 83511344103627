import { MenuItem, Select, styled } from '@mui/material';

import { PreviewSizes } from '/constants/file';

export const ItemText = styled(MenuItem)`
  text-transform: none;
`;

export const ItemIcon = styled('div')`
  width: ${PreviewSizes.xsmall}px;
  height: ${PreviewSizes.xsmall}px;
  margin-right: 5px;
`;

export const SelectStyled = styled(Select)`
  & .MuiSelect-select {
    display: flex;
    align-items: center;
  }
`;
